import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import TradeChecker from './TradeChecker';
import Page404 from './Page404';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import $ from 'jquery';
import Popper from 'popper.js';
import Home from './Home';
import ReactGA from 'react-ga';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
  } from "react-router-dom";
  import ScrollToTop from './ScrollToTop.js';

function App() {
	return (
/*   <React.Fragment>
    <Home />
  </React.Fragment> */
  <Router>
	  	<ScrollToTop />
		<Switch>
		<Route exact path="/" component={Home} />
		<Route path="/tradeChecker" component={TradeChecker} />
		<Route component={Page404}/>
		</Switch>
 </Router>
	);
}

ReactDOM.render(
	<App />,
	document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
