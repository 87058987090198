import React, { Component } from "react";
import classnames from "classnames";
import logo from './logo.svg';
import './App.css';
import ReactGA from 'react-ga';


export class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true
    };
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;
    const visible = prevScrollpos > currentScrollPos;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible
    });
  };

  render() {
    return (
      <nav
        className={classnames("navbar", {
          "navbar--hidden": !this.state.visible
        })}
      >
        <a href="#hero" className="nav-item">Home</a>
        <a href="#meetme" className="nav-item">Meet Me</a>
        <a href="#skills" className="nav-item">Skills</a>
        <a href="#portfolio" className="nav-item">Portfolio</a>
        <a href="#resume" className="nav-item">Resume</a>
      </nav>
    );
  }
}
