import React, { Component } from 'react';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';
import $ from 'jquery';
import Popper from 'popper.js';
import './styles.css';
import personal from "./images/personal.jpeg";
import reactLogo from "./images/react.png";
import bracketsLogo from "./images/brackets.png";
import {Navbar} from './Navbar.js';
import twitter from "./images/twitter.png";
import linkedin from "./images/linkedin.png";
import tradeThumbnail from "./images/tradeThumbnail.PNG";
import resume from "./files/resume.pdf";
import './App.css';
import { MDBBtn } from "mdbreact";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
  } from "react-router-dom";


export default class Home extends Component {

	render(){
		return(
			<div className="home">				
				<div><Navbar>
					</Navbar>
				</div>
				<div className="image-box" id="hero">
						  			<div className="col-lg-6">
										<h1>Patrick Satkunananthan</h1>
										<br/>
										<h3>Aspiring Front-end Developer</h3>
										<h5>HTML/CSS | Node.js | React.js </h5>
									</div>
				</div>
				<div className="container home" id="meetme">
								<div className="row h-100">
									<div className="col-lg-6 my-auto">
										<div className="d-flex justify-content-center">
											<img src={personal}/>
										</div>
									</div>
									<div className="col-lg-6 my-auto">
										<h1>Meet Me</h1>
										<br/>
										<h7>I'm currently in San Diego, where I've been located for almost 7 years, working in various software test and integration roles at prominent genomics companies. </h7>
										<br/>
										<br/>
										<h7>I've made significant contributions to UI automation testing and development, and I'm eager to fully immerse myself into the world of front-end web development. </h7>
									</div>
								</div>
				</div> 
				<div className="section" id="skills">
						  		<div className="container-fluid h-100 d-flex justify-content-center mt-5">
						  			<div className="col-lg-8 vh-100 justify-content-center mt-5 pt-5">
										<div className="row">
											<div className="col-lg-12 d-flex justify-content-center mt-5">
												<h1>Skills</h1>
											</div>
										</div>										
										<div className="row skill-desc-row">
													<div className="col-lg-6">
														
														<div class="row d-flex justify-content-center">
															<img src={reactLogo}/>
														</div>
														<div class="row d-flex justify-content-center mt-2 pt-3">
															<h4>React</h4>
														</div>
														<div class="row d-flex justify-content-center mt-2 ml-5 mr-5">
															<h7>I enjoy creating sites that are built on one of the newest, most responsive technologies that utilized now. Enjoy quick rendering and beautiful, concise UI components.</h7>
														</div>
													</div>
													<div className="col-lg-6 ">
														
														<div class="row d-flex justify-content-center">
															<img src={bracketsLogo}/>
														</div>
														<div class="row d-flex justify-content-center mt-2 pt-3">
															<h4>End-to-End Web Development</h4>
														</div>
														<div class="row d-flex justify-content-center mt-2 ml-5 mr-5">
																<h7>If you need integration with back-end functionalities, I can bring the experience that you need. I've worked extensively with Node.js, SQL, and .NET technologies and can provide the seamless pipelining to smooth HTML/CSS sites.</h7>
														</div>
													</div>
										</div>
									</div>
								</div>
				</div>
				<div className="container home" id="portfolio">
								<div className="row h-100">
									<div className="col-lg-6 my-auto">
										<h1>Portfolio</h1>
										<br/>
										<h3>NBA Trade Checker (2 teams)</h3>
										<br/>
										<h7>This is an example of an NBA trade checker, in which the user can test various trades between two teams to see if the trade can be executed in accordance with the current NBA salary cap rules. </h7>
									</div>
								<div className="col-lg-6 my-auto">
									<Link to='/tradeChecker' >
										<img alt="tradeThumbnail" src={tradeThumbnail} width="500" height="250"/>
									</Link>
									</div>
								</div>
				</div>
				<div className="image-box3" id="resume">
								<div className="col-lg-6">
						  			<div className="col-lg-12 row">
										<h3>I'm keenly interested in utilizing my growing front-end knowledge to suit your needs.</h3>
										<br/>
										<h5>If you're looking for anything from a eye-popping, color-exploded web app to a  sleek, minimalistic web site that is user-friendly and exudes professionalism, please feel free to contact me using any of the below channels. </h5>
									</div>
									<div className="col-lg-12 row mt-3 justify-content-left">
										<div className="col-lg-2 reducepadding">
											
											<Link to={resume} target="_blank" download>
												<button className="download reducepadding" type="button" class="btn btn-primary-outline">Download Resume</button>
											</Link>
											
										</div>
										<div className="col-lg-2">
											<a href="http://www.twitter.com/patsat" className="increasepadding">
													<img alt="twitter" src={twitter} width="30" height="30"/>
											</a>
											<a href="http://www.linkedin.com/in/patrick-satkunananthan" className="increasepadding">
													<img alt="linkedin" src={linkedin} width="30" height="30"/>
											</a>
										</div>
									</div>
								</div>
				</div>
						<br/>
						<br/>
						<br/>
						<br/>
			</div>
			)
	}
}
