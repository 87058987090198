import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import'bootstrap-css-only/css/bootstrap.min.css';
import'mdbreact/dist/css/mdb.css';
import './tradeChecker.css';
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCol, MDBRow } from
'mdbreact';
import lakersLogo from "./images/lakers.png";
import warriorsLogo from "./images/warriors.png";
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link
  } from "react-router-dom";

let salaryCapMax = 109.14;
let luxuryTaxThreshold = 132.63;
let lakersCurrentCap = 122.72;
let warriorsCurrentCap = 131.52;

class TradeChecker extends React.Component{

    constructor(props){
        super(props);
        this.state={
          lakersSalaryTotal: 0,
          warriorsSalaryTotal: 0,
          lakersPlayersTraded: [],
          warriorsPlayersTraded: [],
          tradeLegal:false,
          modal16: false,
          modal17: false,
          tradeDescription: null

        };
        this.editTrade = this.editTrade.bind(this);
        this.displayData = this.displayData.bind(this);
        this.calcTradeLegal = this.calcTradeLegal.bind(this);
     }

    toggle = nr => () => {
    console.log(this.state.modal16);
    let modalNumber = 'modal' + nr
    this.setState({
        [modalNumber]: !this.state[modalNumber]
    });
    console.log(this.state.modal16);
    console.log(this.state.tradeLegal);
    }

 editTrade = (event) => {
    let lakersWorkingSalaryTotal = this.state.lakersSalaryTotal;
    let warriorsWorkingSalaryTotal = this.state.warriorsSalaryTotal;
    let lakersWorkingPlayersTraded = this.state.lakersPlayersTraded;
    let warriorsWorkingPlayersTraded = this.state.warriorsPlayersTraded;
    let rowParent = event.target.parentNode.parentNode;
    let playerSalary = parseFloat(rowParent.querySelector(".currentSalary").innerHTML);
    let playerName = rowParent.querySelector(".playerName").innerHTML;
    let lakersIndex = this.state.lakersPlayersTraded.indexOf(playerName);
    let warriorsIndex = this.state.warriorsPlayersTraded.indexOf(playerName);
    console.log(lakersIndex);
    console.log(warriorsIndex);

    if (event.target.checked)
    {
        if (rowParent.classList.contains("lakers"))
        {
            if (lakersIndex == -1){
                lakersWorkingSalaryTotal += playerSalary;
                lakersWorkingPlayersTraded.push(playerName);
            }
        }
        else
        {
            if (warriorsIndex == -1){
                warriorsWorkingSalaryTotal += playerSalary;
                warriorsWorkingPlayersTraded.push(playerName);
                console.log(warriorsWorkingSalaryTotal);
                console.log(warriorsWorkingPlayersTraded);
            }
        }
    }
    else
    {
        if (rowParent.classList.contains("lakers"))
        {
            if (lakersIndex > -1){
                lakersWorkingSalaryTotal -= playerSalary;
                lakersWorkingPlayersTraded.splice(lakersIndex,1)
            }
        }
        else
        {
            if (warriorsIndex > -1){
                warriorsWorkingSalaryTotal -= playerSalary;
                warriorsWorkingPlayersTraded.splice(warriorsIndex,1)
                console.log(warriorsWorkingSalaryTotal);
                console.log(warriorsWorkingPlayersTraded);
            }
        }
    }
    
    let lakersDisplayTotal = Number(lakersWorkingSalaryTotal.toFixed(2));
    let warriorsDisplayTotal = Number(warriorsWorkingSalaryTotal.toFixed(2));
    this.setState({
        lakersSalaryTotal: lakersDisplayTotal,
        warriorsSalaryTotal: warriorsDisplayTotal
    });

    document.getElementById('testoutput').innerHTML = lakersDisplayTotal;
    document.getElementById('testoutput2').innerHTML = warriorsDisplayTotal;
 }

 displayData = () => {
    document.getElementById('testoutput').innerHTML = this.state.lakersSalaryTotal;
    document.getElementById('testoutput2').innerHTML = this.state.warriorsSalaryTotal;
 }

 calcTradeLegal = (lakersDisplayTotal, warriorsDisplayTotal) => {

    let lakersAfterTradeCap = lakersCurrentCap - lakersDisplayTotal + warriorsDisplayTotal;
    let warriorsAfterTradeCap = warriorsCurrentCap - warriorsDisplayTotal + lakersDisplayTotal;
    console.log(lakersAfterTradeCap);
    console.log(warriorsAfterTradeCap);

    if (lakersAfterTradeCap > luxuryTaxThreshold)
    {
        if (warriorsDisplayTotal > ( (lakersDisplayTotal * 1.25) + 0.1))
        {
            this.setState({
                tradeLegal: false,
                modal17: true,
                tradeDescription: "Due to the Lakers' post-trade team salary being above the luxury cap threshold, the incoming player may not exceed 125% of the pre-trade salary of the traded player plus $100000."
            })
        }
        else
        {
            this.setState({
                tradeLegal: true,
                modal16: true,
                tradeDescription: "Congratulations on a successful trade!"
            })
        }
    }
    else if (lakersAfterTradeCap > salaryCapMax)
    {
        if (warriorsDisplayTotal < 6.53){
            if (warriorsDisplayTotal > ( (lakersDisplayTotal * 1.75) + 0.1))
            {
                this.setState({
                    tradeLegal: false,
                    modal17: true,
                    tradeDescription: "Due to the Lakers' post-trade team salary being above the salary cap max but below the luxury cap threshold, the incoming player's salary cannot exceed 175% of the pre-trade salary of the traded player plus $100000."
                })
            }
            else
            {
                this.setState({
                    tradeLegal: true,
                    modal16: true,
                    tradeDescription: "Congratulations on a successful trade!"
                })
            }
        }

        else if (warriorsDisplayTotal >= 6.53 && warriorsDisplayTotal <= 19.6)
        {
            if (warriorsDisplayTotal >  (lakersDisplayTotal + 5))
            {
                this.setState({
                    tradeLegal: false,
                    modal17: true,
                    tradeDescription: "Due to the Lakers' post-trade team salary being above the salary cap max but below the luxury cap threshold, the incoming player's salary cannot exceed the pre-trade salary of the traded player plus $5000000."
                })
            }
            else
            {
                this.setState({
                    tradeLegal: true,
                    modal16: true,
                    tradeDescription: "Congratulations on a successful trade!"
                })
            }
        }
        else if (warriorsDisplayTotal > 19.6){
            if (warriorsDisplayTotal >  ((lakersDisplayTotal * 1.25) + 0.1))
            {
                this.setState({
                    tradeLegal: false,
                    modal17: true,
                    tradeDescription: "Due to the Lakers' post-trade team salary being above the salary cap max but below the luxury cap threshold, the incoming player's salary cannot exceed 125% of the pre-trade salary of the traded player plus $100000."
                })
            }
            else
            {
                this.setState({
                    tradeLegal: true,
                    modal16: true,
                    tradeDescription: "Congratulations on a successful trade!"
                })
            }
        }
    }
    else if (warriorsAfterTradeCap > luxuryTaxThreshold)
    {
        if (lakersDisplayTotal > ( (warriorsDisplayTotal * 1.25) + 0.1 ))
        {
            this.setState({
                tradeLegal: false,
                modal17: true,
                tradeDescription: "Due to the Warriors' post-trade team salary being above the luxury cap threshold, the incoming player may not exceed 125% of the pre-trade salary of the traded player plus $100000."
            })
        }
        else
        {
            this.setState({
                tradeLegal: true,
                modal16: true,
                tradeDescription: "Congratulations on a successful trade!"
            })
        }
    }
    else if (warriorsAfterTradeCap > salaryCapMax)
    {
        if (lakersDisplayTotal < 6.53){
            if (lakersDisplayTotal > ( (warriorsDisplayTotal * 1.75) + 0.1))
            {
                this.setState({
                    tradeLegal: false,
                    modal17: true,
                    tradeDescription: "Due to the Warriors' post-trade team salary being above the salary cap max but below the luxury cap threshold, the incoming player's salary cannot exceed 175% of the pre-trade salary of the traded player plus $100000."
                })
            }
            else
            {
                this.setState({
                    tradeLegal: true,
                    modal16: true,
                    tradeDescription: "Congratulations on a successful trade!"
                })
            }
        }

        else if (lakersDisplayTotal >= 6.53 && lakersDisplayTotal <= 19.6)
        {
            if (lakersDisplayTotal >  (warriorsDisplayTotal + 5))
            {
                this.setState({
                    tradeLegal: false,
                    modal17: true,
                    tradeDescription: "Due to the Warriors' post-trade team salary being above the salary cap max but below the luxury cap threshold, the incoming player's salary cannot exceed the pre-trade salary of the traded player plus $5000000."
                })
            }
            else
            {
                this.setState({
                    tradeLegal: true,
                    modal16: true,
                    tradeDescription: "Congratulations on a successful trade!"
                })
            }
        }
        else if (lakersDisplayTotal > 19.6){
            if (lakersDisplayTotal >  ((warriorsDisplayTotal * 1.25) + 0.1))
            {
                this.setState({
                    tradeLegal: false,
                    modal17: true,
                    tradeDescription: "Due to the Warriors' post-trade team salary being above the salary cap max but below the luxury cap threshold, the incoming player's salary cannot exceed 125% of the pre-trade salary of the traded player plus $100000."
                })
            }
            else
            {
                this.setState({
                    tradeLegal: true,
                    modal16: true,
                    tradeDescription: "Congratulations on a successful trade!"
                })
            }
        }
        
    }
    else
    {
        this.setState({
            tradeLegal: true,
            modal16: true,
            tradeDescription: "Congratulations on a successful trade!"
        })
    }
 }

 render(){   
  return (
    <div className="tradeChecker">
       <Link to="/">Return to Home</Link>
       <div className="container-fluid row ml-5 mr-5 mb-1 overrides">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <h2 align="center">Los Angeles Lakers</h2>
                  <h5 align="center">2019-2023</h5>
                  <p align="center"><img src={lakersLogo} alt="Lakers logo" width="150" height="100"/></p>
              <div className="table-responsive">
                      <table className="table mytable">
                          <tr>
                              <th className="header"></th>
                              <th className="header"></th>
                              <th colSpan="5" className="header">Salary Cap Figure (dollars in millions)</th>
                          </tr>
                          <tr>
                              <th className="header">Include in Trade?</th>
                              <th align="left" className="header">Player</th>
                              <th className="header">2019-20</th>
                              <th className="header">2020-21</th>
                              <th className="header">2021-22</th>
                              <th className="header">2022-23</th>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)} />    &nbsp;   </td>
                              <td className="player playerName">Lebron James</td>
                              <td className="player currentSalary">37.44</td>
                              <td className="player">39.22</td>
                              <td className="player">41</td>
                              <td className="player">43.05</td>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)} />    &nbsp;   </td>
                              <td className="player playerName">Anthony Davis</td>
                              <td className="player currentSalary">27.09</td>
                              <td className="player">28.75</td>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Danny Green</td>
                              <td className="player currentSalary">14.63</td>
                              <td className="player">15.37</td>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Avery Bradley</td>
                              <td className="player currentSalary">4.77</td>
                              <td className="player">5.0</td>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Kentavious Caldwell-Pope</td>
                              <td className="player currentSalary">8.09</td>
                              <td className="player">8.49</td>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Javale McGee</td>
                              <td className="player currentSalary">4.00</td>
                              <td className="player">4.20</td>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Quinn Cook</td>
                              <td className="player currentSalary">3.00</td>
                              <td className="player">3.07</td>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Alex Caruso</td>
                              <td className="player currentSalary">2.75</td>
                              <td className="player">2.75</td>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Rajon Rondo</td>
                              <td className="player currentSalary">2.56</td>
                              <td className="player">2.62</td>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Kyle Kuzma</td>
                              <td className="player currentSalary">1.97</td>
                              <td className="player">3.56</td>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Markieff Morris</td>
                              <td className="player currentSalary">1.75</td>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Jared Dudley</td>
                              <td className="player currentSalary">1.62</td>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Dwight Howard</td>
                              <td className="player currentSalary">1.62</td>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Talen Horton-Tucker</td>
                              <td className="player currentSalary">0.90</td>
                              <td className="player">1.52</td>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Dion Waiters</td>
                              <td className="player currentSalary">0.90</td>
                          </tr>
                          <tr className="lakers">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">J.R. Smith</td>
                              <td className="player currentSalary">0.29</td>
                          </tr>         
                      </table>
                  </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                  <h2 align="center">Golden State Warriors</h2>
                  <h5 align="center">2019-2023</h5>
                  <p align="center"><img src={warriorsLogo} alt="Lakers logo" width="100" height="92"/></p>
              <div className="table-responsive">
                      <table className="table mytable">
                          <tr>
                              <th className="header"></th>
                              <th className="header"></th>
                              <th colspan="5" className="header">Salary Cap Figure (dollars in millions)</th>
                          </tr>
                          <tr>
                              <th className="header">Include in Trade?</th>
                              <th align="left" className="header">Player</th>
                              <th className="header">2019-20</th>
                              <th className="header">2020-21</th>
                              <th className="header">2021-22</th>
                              <th className="header">2022-23</th>
                          </tr>
                          <tr className="warriors">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)} />    &nbsp;   </td>
                              <td className="player playerName">Stephen Curry</td>
                              <td className="player currentSalary">40.23</td>
                              <td className="player">39.22</td>
                              <td className="player">41</td>
                              <td className="player">43.05</td>
                          </tr>
                          <tr className="warriors">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)} />    &nbsp;   </td>
                              <td className="player playerName">Klay Thompson</td>
                              <td className="player currentSalary">32.74</td>
                              <td className="player">32.82</td>
                          </tr>
                          <tr className="warriors">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Andrew Wiggins</td>
                              <td className="player currentSalary">27.50</td>
                              <td className="player">15.37</td>
                          </tr>
                          <tr className="warriors">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Draymond Green</td>
                              <td className="player currentSalary">18.54</td>
                              <td className="player">8.31</td>
                          </tr>
                          <tr className="warriors">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Kevon Looney</td>
                              <td className="player currentSalary">4.46</td>
                              <td className="player">4.26</td>
                          </tr>
                          <tr className="warriors">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Jordan Poole</td>
                              <td className="player currentSalary">1.96</td>
                              <td className="player">3.56</td>
                          </tr>
                          <tr className="warriors">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Eric Paschall</td>
                              <td className="player currentSalary">0.90</td>
                              <td className="player">3.07</td>
                          </tr>
                          <tr className="warriors">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Alen Smailagic</td>
                              <td className="player currentSalary">0.90</td>
                              <td className="player">2.85</td>
                          </tr>
                          <tr className="warriors">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Damion Lee</td>
                              <td className="player currentSalary">0.84</td>
                              <td className="player">2.62</td>
                          </tr>
                          <tr className="warriors">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Marquese Chriss</td>
                              <td className="player currentSalary">0.65</td>
                          </tr>
                          <tr className="warriors">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Ky Bowman</td>
                              <td className="player currentSalary">0.35</td>
                          </tr>
                          <tr className="warriors">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Juan Toscano-Anderson</td>
                              <td className="player currentSalary">0.35</td>
                          </tr>
                          <tr className="warriors">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Mychal Mulder</td>
                              <td className="player currentSalary">0.19</td>     
                          </tr>
                          <tr className="warriors">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Dragan Bender</td>
                              <td className="player currentSalary">0.92</td>     
                          </tr>
                          <tr className="warriors">
                              <td className="player"><input type="checkbox" onClick={(event) => this.editTrade(event)}/>    &nbsp;   </td>
                              <td className="player playerName">Chasson Randle</td>
                              <td className="player currentSalary">0.92</td>     
                          </tr>          
                      </table>
                  </div>
              </div>
          </div>
        <div className="container-fluid row mb-3">
            <h6 className="col-lg-6" id="lakersTotal">Approximate Current Cap Total: {lakersCurrentCap}</h6>
            <h6 className="col-lg-6" id="warriorsTotal">Approximate Current Cap Total: {warriorsCurrentCap}</h6>
        </div>
        <div className="container-fluid">
            <h6>Approximate Salary Included in Trade (dollars in millions):</h6>
        </div>
        <div className="container-fluid row mt-3">
          <h3 className="col-lg-6" id="testoutput">0</h3>
          <h3 className="col-lg-6" id="testoutput2">0</h3>
        </div>
        <div>
            <MDBContainer>
                <MDBBtn color="primary" onClick={() => { this.calcTradeLegal(this.state.lakersSalaryTotal, this.state.warriorsSalaryTotal) }}>EXECUTE</MDBBtn>
                <MDBModal isOpen={this.state.modal16 && this.state.tradeLegal} toggle={this.toggle(16)}>
                <MDBModalHeader className="text-white" toggle={this.toggle(16)} style={{ background: "#00C851" }}>Successful Trade</MDBModalHeader>
                <MDBModalBody>
                    <MDBContainer fluid className="text-black">
                    <MDBRow>
                        <MDBCol md="6" className="text-white" style={{ background: "#552583" }}>To Lakers:                         
                        </MDBCol>
                        <MDBCol md="6" className="text-white" style={{ background: "#006bb6" }}>To Warriors:
                        </MDBCol>
                    </MDBRow >
                        <MDBRow >
                            <MDBCol md="6">
                                {this.state.warriorsPlayersTraded.map((player, index) => ( 
                                <MDBCol md="12" className="font-weight-bolder">{player}</MDBCol>))}
                            </MDBCol>
                            <MDBCol md="6">
                                {this.state.lakersPlayersTraded.map((player, index) => ( 
                                <MDBCol md="12" className="font-weight-bolder">{player}</MDBCol>))}
                            </MDBCol>
                        </MDBRow>
                    <br />
                    </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBCol className="text-black" sm="12">{this.state.tradeDescription}</MDBCol>
                    <MDBBtn color="success" onClick={this.toggle(16)}>Close</MDBBtn>
                </MDBModalFooter>
                </MDBModal>
            <MDBModal isOpen={this.state.modal17 && !this.state.tradeLegal} toggle={this.toggle(17)}>
                <MDBModalHeader className="text-white" toggle={this.toggle(17)} style={{ background: "#ff4444" }}>Illegal Trade</MDBModalHeader>
                <MDBModalBody>
                    <MDBContainer fluid className="text-black">
                    <MDBRow>
                        <MDBCol md="6" className="text-white" style={{ background: "#552583" }}>To Lakers:                         
                        </MDBCol>
                        <MDBCol md="6" className="text-white" style={{ background: "#006bb6" }}>To Warriors:
                        </MDBCol>
                    </MDBRow>
                        <MDBRow>
                            <MDBCol md="6">
                                {this.state.warriorsPlayersTraded.map((player, index) => ( 
                                <MDBCol md="12">{player}</MDBCol>))}
                            </MDBCol>
                            <MDBCol md="6">
                                {this.state.lakersPlayersTraded.map((player, index) => ( 
                                <MDBCol md="12">{player}</MDBCol>))}
                            </MDBCol>
                        </MDBRow>
                    <br />
                    </MDBContainer>
                </MDBModalBody>
                <MDBModalFooter>
                    <MDBCol className="text-black" sm="12">{this.state.tradeDescription}</MDBCol>
                    <MDBBtn color="danger" onClick={this.toggle(17)}>Close</MDBBtn>
                </MDBModalFooter>
                </MDBModal>
            </MDBContainer>
        </div>

        
    </div>
  );
}
}

export default TradeChecker;
